/**
 * CkModal Component
 *
 * `CkModal` is a wrapper around Ant Design's Modal that provides customizable features such as specifying orientation of action buttons, handling action button events. 
 * It provides an organized templated modal dialog box to display content and actionable buttons with header, body, and footer. It allows modal-wide theming based on `type` prop and more.
 *
 * @component
 * @example
 * <CkModal
 *   title="My Modal"
 *   type="default"
 *   visible={true}
 *   primaryAction={{
 *     label: "Primary Action",
 *     onClick: () => alert("Primary Action"),
 *     order: 2
 *   }}
 * />
 *
 * @extends {ModalProps}
 *
 * @property {ReactNode} children - Optional: Content displayed inside the Modal body
 * @property {ReactNode} actions - Optional: Additional actions or content that appear in the Modal footer
 * @property {string} className - Optional: Additional CSS classes to apply
 * @property {string} title - Optional: Title of the Modal
 * @property {ReactNode} headerIcon - Optional: Icon situated at top of the Modal body
 * @property {boolean} columnControlls - Optional: Can show or hide controll buttons
 * @property {IActionButton} primaryAction - Optional: Defines the primary button situated on the footer of the modal
 * @property {IActionButton} secondaryAction - Optional: Defines the secondary button situated on the footer of the modal
 * @property {IActionButton} tertiaryAction - Optional: Defines the tertiary button situated on the footer of the modal
 * @property {OrientationProps | "triangle" | ModalResponsiveOrientationProps} actionButtonsDirection - Optional: Specifies the layout or direction of action buttons
 * @property {"default" | "delete"} type - Optional: Defines how the modal should be themed
 */
import React, { FC, ReactNode, MouseEventHandler } from "react";
import { Modal, ModalProps } from "antd";
import CkIcon, { CkIconNames } from "../../atoms/CkIcon";
import CkButton, { CkButtonProps } from "../CkButton";
import { translateOrder, translateOrientation, OrientationProps, ModalResponsiveOrientationProps } from "../../../utils";
import "./styles.css";

interface IActionButton extends CkButtonProps {
  label: string;
  onClick: MouseEventHandler<HTMLElement>;
  order?: 1 | 2 | 3 | {
    responsive:  1 | 2 | 3;
    desktop: 1 | 2 | 3;
  };
}

export interface CkModalProps extends ModalProps {
  /**
   * Optional: Content displayed inside the Modal body
   */
  children?: ReactNode;
  /**
   * Optional: Additional actions or content that appear in the Modal footer
   */
  actions?: ReactNode;
  /**
   * Optional: Additional CSS classes to apply to modal wrapper
   */
  className?: string;
  /**
   * Optional: Title of the Modal
   */
  title?: string;
  /**
   * Optional:
   * If a string is provided, component will try to import CkIcon element. 
   * If ReactNode is provided, this will be used.
   */
  headerIcon?: CkIconNames | ReactNode;
  /**
   * Optional: Can show or hide controll buttons
   */
  columnControlls?: boolean;
  /**
   * Optional: Defines the primary button situated on the footer of the modal. Extends CkModal, variant primary is used by default 
   */
  // Primary Button
  primaryAction?: IActionButton;
  /**
   * Optional: Defines the secondary button situated on the footer of the modal. Extends CkModal, variant secondary is used by default
   */
  // Secondary Button
  secondaryAction?: IActionButton;
  /**
   *  Optional: Defines the tertiary button situated on the footer of the modal. Extends CkModal, variant link is used by default
   */
  // Link Button
  tertiaryAction?: IActionButton;
  /**
   * Optional: Specifies the layout or direction of action buttons
   * responsive object will use 768px as breakpoint
   */
  actionButtonsDirection?: OrientationProps | "triangle" | ModalResponsiveOrientationProps;
  /**
   * Optional: Defines how the modal should be themed
   * Delete theme will set title red, and primary button to red
   */
  type?: "default" | "delete";
}

const CkModal: FC<CkModalProps> = ({
  children,
  className,
  actions,
  title,
  headerIcon,
  columnControlls,
  primaryAction,
  secondaryAction,
  tertiaryAction,
  actionButtonsDirection,
  type = "default",
  ...props
}) => {
  return (
    <Modal
      wrapClassName={["ck-modal", `${type}-theme`, ...(className ? [className] : [])].join(" ")} 
      closeIcon={<CkIcon name="close" />}
      footer={null}
      {...props}
      getContainer={(e) => document.querySelector(".root .ant-layout") || e}
    >
      {headerIcon && (
        <div className="header-icon-container">
          {headerIcon && typeof headerIcon === "string" && <CkIcon name={headerIcon as CkIconNames} />}
          {headerIcon && typeof headerIcon !== "string" && headerIcon}
        </div>)}
      {title && <h1 className="modal-title">{title}</h1>}
      {children && children}
      {(primaryAction || secondaryAction || tertiaryAction)  &&
        <div className={["actions-container", ...(actionButtonsDirection ? [translateOrientation(actionButtonsDirection)] : [])].join(" ")}>
          {primaryAction &&
            <CkButton
              variant={primaryAction?.variant || "primary"}
              onClick={(e) => primaryAction.onClick && primaryAction?.onClick(e)}
              {...(type === "delete" ? {color: "danger"} : {})}
              {...(primaryAction?.order ? {className: translateOrder(primaryAction.order)} : {})}
              {...(primaryAction || {})}
            >
              {primaryAction.label}
            </CkButton>}
          {secondaryAction &&
            <CkButton
              variant={secondaryAction?.variant || "secondary"}
              onClick={(e) => secondaryAction.onClick && secondaryAction?.onClick(e)}
              {...(secondaryAction?.order ? {className: translateOrder(secondaryAction.order)} : {})}
              {...(secondaryAction || {})}
            >
              {secondaryAction.label}
            </CkButton>}
          {tertiaryAction &&
            <CkButton
              variant={tertiaryAction?.variant || "link"}
              onClick={(e) => tertiaryAction?.onClick && tertiaryAction?.onClick(e)}
              block={false}
              {...(tertiaryAction?.order ? {className: translateOrder(tertiaryAction.order)} : {})}
              {...(tertiaryAction || {})}
            >
              {tertiaryAction.label}
            </CkButton>}
          
        </div>}
        {actions && actions}
    </Modal>
  )
}

export default CkModal;
